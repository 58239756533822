<template>
  <a-card :bordered="false" class="card-area">
    <div class="search" v-if="false">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item" style="width: 100px">
          <a-select
              class="input"
              v-model="queryParams.type"
              placeholder="类型"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict.careerType" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.dataName"
              placeholder="名称"
              allowClear
          />
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.parentId"
              placeholder="上级ID"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <!--        <a-button style="margin-left: 8px" @click="reset">重置</a-button>-->
      </div>
      <a-button type="primary" @click="add" style="margin-left:20px;" icon="plus">
        新增
      </a-button>
    </div>
    <div>
      <a-table style="margin-top:-10px;"
               ref="TableInfo"
               size="small"
               :bordered="true"
               :columns="columns"
               rowKey="id"
               :dataSource="dataSource"
               :pagination="pagination"
               :loading="loading"
               @change="handleTableChange"
               :scroll="{ x: 1000 }"
      >
        <template slot="status" slot-scope="text, record">
          <a-tag :color="record.status | dictName('validStatus', 'color')" style="cursor: pointer">
            {{ record.status| dictName("validStatus") }}
          </a-tag>
        </template>
        <template slot="type" slot-scope="text, record">
          <a-tag :color="record.type | dictName('careerType', 'color')" style="cursor: pointer">
            {{ record.type| dictName("careerType") }}
          </a-tag>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-button type="primary" icon="edit" size="small" @click="modify(record)">
            修改
          </a-button>
          <a-button type="danger" icon="delete" size="small" style="margin-left:10px" @click="delOne(record)">
            删除
          </a-button>
        </template>
      </a-table>
    </div>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";

export default {
  name: "SysCareerGrid",
  mixins: [ListMixin],
  data() {
    return {
      listGetUrl: "sysCareer/list",
      listMethod: "get",
      parentId: 0,
      pagination: {
        defaultPageSize: 15
      }
    };
  },
  computed: {
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order
        },

        {
          title: "类型",
          width: 80,
          dataIndex: "type",
          align: "center",
          scopedSlots: {customRender: "type"},
        },

        {
          title: "名称",
          align: "center",
          width: 350,
          dataIndex: "dataName",
        },
        {
          title: "状态",
          width: 80,
          dataIndex: "status",
          align: "center",
          sorter: true,
          scopedSlots: {customRender: "status"},
          sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order
        },
        {
          title: "排序",
          width: 80,
          align: "center",
          dataIndex: "sortNumber",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'sortNumber' && sortedInfo.order
        },
        // {
        //   title: "操作",
        //   width: 200,
        //   align: "center",
        //   dataIndex: "operation",
        //   scopedSlots: {customRender: "operation"},
        // },
        {}
      ];
    },
  },

  mounted() {
    this.search();
  },
  methods: {
    searchByParent(parentId) {
      if (this.parentId === parentId) {
        return;
      }
      this.queryParams.parentId = parentId;
      this.search(1)
      this.parentId = parentId;
    },
    editSuccess() {
      this.search();
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
